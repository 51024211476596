import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "./article.css"
import "./videoTemplate.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) {
  const { mdx } = data // data.markdownRemark holds our post data
  const { frontmatter } = mdx
  const { older, newer } = pageContext

  useEffect(() => {
    if (!window.GumroadEmbed)
        return;

    window.GumroadEmbed.reload();
  });

  return (
    <Layout subtitle={frontmatter.title}>
      <Seo title={frontmatter.title} ogImage={frontmatter.image} />

      <div className="article-post-container">
        <div className="article-post">
          <div className="gumroad-product-embed">
            <div className="video-loading-container">
              <div className="video-loading-text">
                <a className="gumroad-button" href={frontmatter.gumlink}>Loading...</a>
              </div>
            </div>
          </div>

          <hr/>

        </div>
      </div>
      <div className="article-nav-container">
         <div className="article-nav-item">{older && <Link to={older.frontmatter.slug}>&lt;Older</Link>}</div>
         <div className="article-nav-item center"><Link to="/videos">Videos</Link></div>
         <div className="article-nav-item right">{newer && <Link to={newer.frontmatter.slug}>Newer&gt;</Link>}</div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(
      frontmatter: { slug: { eq: $slug } }
    ) {
      body
      frontmatter {
        slug
        sku
        title
        gumlink
        image
        summary
        releaseDate(formatString: "MMMM DD, YYYY")
        fighters
      }
    }

    allMdx(
      sort: { fields: [frontmatter___releaseDate], order:DESC }
      filter: {fileAbsolutePath: {regex: "/\/src\/videos\//"}}
    ) {
      edges{
        node{
          frontmatter {
            slug
          }
        }
      }
    }
  }
`
